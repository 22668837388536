import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [username, setUsername] = useState('Usuario')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]

  const generateRandomName = () => {
    const adj = selectRandomItem(data.wordsJson.english.adjectives)
    const noun = selectRandomItem(data.wordsJson.english.nouns)
    setUsername(
      adj.charAt(0).toUpperCase() +
        adj.slice(1) +
        noun.charAt(0).toUpperCase() +
        noun.slice(1)
    )
  }
  useEffect(() => {
    generateRandomName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de nombres para Free Fire aleatorios y originales"
        titleSeo="Generador de nombres para Free Fire aleatorios y originales"
        description="Herramienta para generar nombres para Free Fire al azar que estén disponibles y sean originales."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[
          { name: 'Nombres', url: '/nombres/' },
          { name: 'Juegos', url: '/nombres/juegos/' },
        ]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {username}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de nombres para Instagram originales y chulos
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => generateRandomName()}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de nombres para Free Fire</Heading1>
        <Paragraph>
          Puede parecer una tarea trivial, pero dar con el nombre correcto
          resulta importante, es por ello que aqu&iacute; tienes a tu
          disposici&oacute;n un generador de nombres para Free Fire que puedes
          utilizar al jugar a este popular videojuego y destacar entre los
          usuarios con nombres gen&eacute;ricos o aburridos.
        </Paragraph>
        <Paragraph>
          Si bien no es una tarea que requiera de complejas f&oacute;rmulas ni
          gran esfuerzo t&eacute;cnico, una simple herramienta para generar
          nombres para Free Fire que sean originales y est&eacute;n disponibles
          puede facilitarnos mucho el proceso de escoger el nombre adecuado
          antes de proceder a jugar este battle royale con otras personas.
        </Paragraph>
        <Paragraph>
          Al no ser una cuenta personal ni un perfil en redes sociales, uno
          cuenta con un alto grado de libertad al momento de elegir qu&eacute;
          nombre utilizar en Free Fire, dado que no necesariamente tienen que
          ser nombres legales, autorizados o que hagan referencia a nuestra
          persona.
        </Paragraph>
        <Paragraph>
          Esta libertad tiene un coste: si nos ponemos a pensar demasiado acerca
          de qu&eacute; nombre usar y damos muchas vueltas en b&uacute;squeda
          del nombre perfecto, terminaremos perdiendo nuestro valioso tiempo y
          tardaremos a&uacute;n m&aacute;s en comenzar a jugar.
        </Paragraph>
        <Paragraph>
          La gran mayor&iacute;a de usuarios simplemente colocan el mismo nombre
          de usuario en Free Fire que alguno que ya han utilizado previamente, o
          se inventan alguno que sea simple y gen&eacute;rico. Pero esto no
          significa que no hay lugar para aquellos que deseen dedicarle
          m&aacute;s tiempo y esfuerzo a la elecci&oacute;n de un nombre de
          jugador, para quienes el tener a su disposici&oacute;n un generador de
          nombres para Free Fire puede resultar muy pr&aacute;ctico y
          c&oacute;modo.
        </Paragraph>
        <Heading2>
          Qu&eacute; es Free Fire y por qu&eacute; tu nombre de usuario importa
        </Heading2>
        <Paragraph>
          Tambi&eacute;n conocido como <strong>Free Fire Battlegrounds</strong>{' '}
          o <em>Garena Free Fire</em>, en referencia al gigantesco desarrollador
          singapurense Garena, qui&eacute;n es propietario y creador del mismo
          (en conjunto con 111 Dots Studio), este juego es uno de los{' '}
          <em>battle royale </em>m&aacute;s populares del momento, destacando
          por su fuerte presencia en dispositivos m&oacute;viles, principales en
          smartphones Android, donde puede ser ejecutado y disfrutado con
          m&iacute;nimos requerimientos y desde cualquier parte gracias a lidiar
          bastante de buena manera con los problemas relacionados a la
          conectividad 3G, 4G y similares.
        </Paragraph>
        <Paragraph>
          Pero no hay que dejarse enga&ntilde;ar, el hecho de que sea un battle
          royale no implica que su p&uacute;blico es de nicho ahora que este
          g&eacute;nero ya ha pasado su m&aacute;ximo pico de fama, muy por el
          contrario, result&oacute; ser el juego m&aacute;s descargado de toda
          la tienda de Android (Google Play Store) en el a&ntilde;o 2019. Con
          esto en mente, podemos tener en claro que es un monstruo en cuanto a
          popularidad y los jugadores concurrentes est&aacute;n en las decenas
          de miles todo el tiempo, e incluso m&aacute;s en horarios pico.
        </Paragraph>
        <Paragraph>
          Con tanta popularidad y tant&iacute;simos jugadores que ya se han
          creado una cuenta y escogido un nombre de usuario, resulta evidente el
          por qu&eacute; dar con un nombre nuevo y original puede convertirse en
          algo tedioso y aburrido.
        </Paragraph>
        <Paragraph>
          Pero esto no deber&iacute;a ser motivo de preocupaci&oacute;n al
          contar con un generador de nombres para Free Fire como lo es este, que
          te permitir&aacute; escoger un atractivo y original nombre para jugar
          a este famoso battle royale en tu tel&eacute;fono m&oacute;vil.
        </Paragraph>
        <Heading3>En un battle royale, tu nombre importa</Heading3>
        <Paragraph>
          M&aacute;s all&aacute; de lo que ya se ha comentado respecto a la
          enorme cantidad de nombres ya utilizados, est&aacute; presente el
          hecho de que se trata de un juego donde en cada partida comienzas con
          otros 49 jugadores en un mismo mapa y, poco a poco, vas
          abri&eacute;ndote paso hacia la cima donde tu nombre ser&aacute; el
          que est&eacute; en el podio de resultar vencedor.
        </Paragraph>
        <Paragraph>
          Y es all&iacute; donde tu nombre resulta importante, y el generar un
          buen nombre para Free Fire puede ser interesante, porque los jugadores
          a los que superes vendr&aacute;n tu nombre en pantalla y, quiz&aacute;
          m&aacute;s importante a&uacute;n, ser&aacute; tu nombre el que
          aparezca como ganador si consigues el primer puesto.
        </Paragraph>
        <Paragraph>
          Dado que cada partida comienza desde cero sin acarrear progreso desde
          partidas anteriores, tendr&aacute;s muchas oportunidades para sacar a
          lucir tus habilidades y traer atenci&oacute;n a tu nombre. A lo largo
          del tiempo, ser&aacute;n muchas las personas que ver&aacute;n tu
          nombre en la pantalla de su smartphone.
        </Paragraph>
        <Paragraph>
          Quiz&aacute; conozcas a grandes nombres dentro de otros juegos del
          mismo g&eacute;nero, como pueden serlo Fortnite, PUBG o Apex Legends,
          pero en lo que respecta a Free Fire, tienes una oportunidad de ser
          t&uacute; quien traiga fama y reconocimiento a tu nombre, no solo
          limit&aacute;ndose al juego en s&iacute; mismo, sino que
          extendi&eacute;ndose a la inmensa comunidad online con la que cuenta
          este juego.
        </Paragraph>
        <Heading3>
          Un generador de nombres originales para Free Fire es tan &uacute;til
          como simple
        </Heading3>
        <Paragraph>
          Una herramienta de generaci&oacute;n de nombres para Free Fire es tan
          simple como &uacute;til dado que si bien la tare en s&iacute; misma
          puede hacerse en segundos de forma manual, tambi&eacute;n puede
          complicarse y tomar m&aacute;s tiempo, ante lo cu&aacute;l aqu&iacute;
          tendr&aacute;s cientos (o miles) de nombres disponibles al alcance de
          un clic (o toque de pantalla t&aacute;ctil) y no tendr&aacute;s que
          preocuparte por quedarte sin ideas.
        </Paragraph>
        <Paragraph>
          Adem&aacute;s, esta utilidad online te permitir&aacute; generar el
          nombre que utilizar&aacute;s en las competiciones y torneos de
          eSports, si es que eso est&aacute; dentro de tus planes, por lo que de
          repente un proceso tan sencillo como la elecci&oacute;n de un nombre
          de usuario cobra mucha importancia.
        </Paragraph>
        <Paragraph>
          Ya sea que quieres grabar tus mejores partidas y compartir gameplays,
          haciendo an&aacute;lisis y review de las diferencias opciones
          disponibles y las estrategias m&aacute;s empleadas, o simplemente
          crear contenido relacionado a este videojuego, tu nombre estar&aacute;
          siempre presente y es buena idea que no sea gen&eacute;rico ni
          est&eacute; repetido ya hasta el cansancio, para que a la gente le sea
          m&aacute;s f&aacute;cil reconocerte.
        </Paragraph>
        <Paragraph>
          Si la primera opci&oacute;n que se ha generado no te gusta, no te
          preocupes, tienes infinitos nombres posibles entre los cuales escoger
          antes de saltar al campo de batalla virtual con tu avatar y nombre de
          usuario.
        </Paragraph>
        <Paragraph>
          Como nota final, si bien puede parecer que es fundamental dar con el
          nombre id&oacute;neo, no resulta recomendable el invertir una cantidad
          excesiva de tiempo pensando cada detalle del mismo e intentando
          ajustarlo exactamente a las expectativas que uno tenga porque, al fin
          y al cabo, todo el reconocimiento que uno vaya a recibir
          provendr&aacute; de jugar bien y/o crear contenido acerca del juego.
        </Paragraph>
        <Paragraph>
          C&eacute;ntrate en divertirte y pas&aacute;rtelo bien, tu nombre es
          importante pero no deber&iacute;a ser un motivo de
          preocupaci&oacute;n. En un battle royale el ganador es el
          &uacute;ltimo jugador en pie dentro de la partida, sin importar
          qu&eacute; nombre de usuario haya escogido antes de entrar.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      english {
        adjectives
        nouns
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
